import { useTranslation } from '@utils/index';
import AccountCard, { AccountCardProps } from '../AccountCard/AccountCard';

const getTranslatedTexts = translator => {
	return {
		orderNumberMessage: (orderNumber: string) => {
			return translator('order-number-message', { orderNumber });
		},
		orderTracker: translator('order-tracker'),
	};
};

const OrderTracking = ({ order, ...rest }: Omit<AccountCardProps, 'children'>) => {
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);
	if (!order) return null;

	return (
		<AccountCard
			order={order}
			type='tracking'
			title={translations.orderTracker.toUpperCase()}
			label={order.last_status.title}
			subtitle={translations.orderNumberMessage(order?.order_number).toUpperCase()}
			subCopy={order.last_status.description}
			{...rest}
		/>
	);
};

export default OrderTracking;

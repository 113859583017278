import { translate } from './locales';

export const loyaltyStatusInfo = {
	Spectator: '#F38BB6',
	Sightseer: '#84D0FC',
	Eyecon: '#FF510E',
	Visionary: '#FBE324',
};

type RewardMessage = {
	message: string;
	value: string;
	next?: number;
};

const rewardMessages: { [key: string]: Omit<RewardMessage, 'message'> & { messageKey: string } } = {
	'<50': { messageKey: 'reward-message-less-than-50', value: '$5', next: 50 },
	'50-99': { messageKey: 'reward-message-50-99', value: '$5', next: 100 },
	'100-199': { messageKey: 'reward-message-100-199', value: '$10', next: 200 },
	'200-299': { messageKey: 'reward-message-200-299', value: '$20', next: 300 },
	'300-399': { messageKey: 'reward-message-300-399', value: '$30', next: 400 },
	'400-499': { messageKey: 'reward-message-400-499', value: '$40', next: 500 },
	'500-599': { messageKey: 'reward-message-500-599', value: '$50', next: 600 },
	'600-999': { messageKey: 'reward-message-600-999', value: '+$60', next: 1000 },
	'1000+': { messageKey: 'reward-message-1000+', value: '+$100' },
};

export function getRewardMessage(points: number, locale = 'en'): RewardMessage {
	let key: string;
	if (points < 50) key = '<50';
	else if (points < 100) key = '50-99';
	else if (points < 200) key = '100-199';
	else if (points < 300) key = '200-299';
	else if (points < 400) key = '300-399';
	else if (points < 500) key = '400-499';
	else if (points < 600) key = '500-599';
	else if (points < 1000) key = '600-999';
	else key = '1000+';

	const reward = rewardMessages[key];

	return {
		message: translate(reward.messageKey, locale),
		value: reward.value,
		next: reward.next,
	};
}

import React, { memo, useCallback, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useFormContext } from 'react-hook-form';
import { Checkbox, Flex, Heading, Img, Paragraph, Tooltip, TypographyButton } from '@components';
import { useRXSSContext } from '@context';
import client from '@services/contentful/client';
import {
	RXSS_FIELDS_SELECTION_COPY,
	RXSS_FIELDS_SUBTITLE_COPY,
	RXSS_FIELDS_TITLE_COPY,
	RXSS_FIELD_INPUT_IMAGE_ID,
	RXSS_FIELD_OPTIONS,
} from '@utils/constants/rxss';
import { RxForm, bulkResetRxFields } from '@ts/rxss';
import styles from './Fields.module.scss';

const Fields = () => {
	const { selectedFields, setSelectedFields, setIsPdFieldSelected, isPdFieldSelected, setIsNextStepBlocked, setHasTwoPds } =
		useRXSSContext();
	const { setValue, getValues, reset } = useFormContext();

	const ContentfulAsset = useQuery(['rxss-field-input-image'], async () => {
		const fetchedAsset = await client.getAsset(RXSS_FIELD_INPUT_IMAGE_ID);
		return fetchedAsset.fields;
	});

	const sendFieldUpdate = useCallback(
		(field: keyof RxForm) => {
			if (selectedFields.includes(field)) {
				const prevSelectedFields = [...selectedFields];
				bulkResetRxFields(field, setValue);
				const values = getValues();
				reset(values);
				const index = prevSelectedFields.indexOf(field);
				prevSelectedFields.splice(index, 1);
				setSelectedFields(() => [...prevSelectedFields]);

				if (field === 'pd' && isPdFieldSelected) {
					setIsPdFieldSelected(false);
				}
				setHasTwoPds(false);
				return;
			}

			setSelectedFields(prevState => [...prevState, field]);
			if ([...selectedFields, field].includes('pd') && !isPdFieldSelected) {
				setIsPdFieldSelected(true);
			}
		},
		[selectedFields, setValue, isPdFieldSelected]
	);

	useEffect(() => {
		if (selectedFields.length === 1 && selectedFields.includes('pd')) {
			setIsNextStepBlocked(true);
			return;
		}
		setIsNextStepBlocked(selectedFields.length === 0);
	}, [selectedFields]);

	return (
		<div className={styles['container']}>
			<Flex column fullWidth fullHeight style={{ flexGrow: '1' }}>
				{ContentfulAsset.isFetched ? (
					<Img noSrcset src={ContentfulAsset.data.file.url} className={styles.image} />
				) : (
					<div className={styles.image} />
				)}
				<Flex
					column
					align='start'
					pad={4}
					gap={3}
					backgroundColor='white'
					noBorder
					className={styles['content-container']}
				>
					<Flex column align='start' gap={2} className={styles.fieldsCopy} fullWidth>
						<Heading tag='h6' style={{ margin: '0' }}>
							{RXSS_FIELDS_TITLE_COPY}
						</Heading>
						<Paragraph>{RXSS_FIELDS_SUBTITLE_COPY}</Paragraph>
					</Flex>
					<Flex align='end'>
						<TypographyButton small>{RXSS_FIELDS_SELECTION_COPY}</TypographyButton>
					</Flex>
					<Flex column align='start' gap={3} fullWidth>
						{Object.entries(RXSS_FIELD_OPTIONS).map(([option, { label, tooltip = null }]) => {
							return (
								<Checkbox
									key={`field-input-${option}`}
									option={option}
									handler={(field: keyof RxForm) => sendFieldUpdate(field)}
									className={styles.fieldOption}
									checked={selectedFields.includes(option as keyof RxForm)}
									dataTags={{
										[`data-rx-field`]: option,
									}}
								>
									<Flex fullWidth justify='between' align='center'>
										<Paragraph>{label}</Paragraph>
										{tooltip && <Tooltip message={tooltip} />}
									</Flex>
								</Checkbox>
							);
						})}
					</Flex>
				</Flex>
			</Flex>
		</div>
	);
};

export default memo(Fields);

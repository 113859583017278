import { Flex, LineItemCard, Paragraph } from '@components';
import { LineItemPOMS } from '@ts/poms/orders';
import { useTranslation } from '@utils/index';
import styles from './AccountCard.module.scss';

type ProductListProps = {
	items: LineItemPOMS[];
};

const getTranslatedTexts = translator => {
	return {
		accountCardListItems: (itemsNumber: number) => {
			return translator('account-card-list-items', { itemsNumber });
		},
		accountCardListItem: (itemNumber: number) => {
			return translator('account-card-list-item', { itemNumber });
		},
	};
};

export const ProductList = ({ items }: ProductListProps) => {
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);

	const mappedLineItems = items
		.slice(0, 2)
		.map(item => <LineItemCard key={`line-item-${item.id}`} data={item} dataSource={'poms'} noInteraction />);

	const onlyOneItem = items.length - 2 === 1;
	return (
		<div>
			<Flex column gap={3}>
				{mappedLineItems}
				{true && (
					<Paragraph className={styles.remainingItems}>
						{`${
							onlyOneItem
								? translations.accountCardListItem(items.length - 2)
								: translations.accountCardListItems(items.length - 2)
						}`}
					</Paragraph>
				)}
			</Flex>
		</div>
	);
};

import cn from 'classnames';
import { Checkmark, TypographyButton } from '@components';
import { capitalize } from '@utils/strings';
import { FRAME_COLOR_IMAGE_LINKS, REFINEMENT_FILTER_COLORS } from '@constants';
import { useFilterContext } from '@context';
import styles from './RefinementSwatch.module.scss';

const RefinementSwatch = ({ label, isRefined, refine, type: refinementType, value }) => {
	const { type } = useFilterContext();
	const swatchClasses = cn(styles.swatch, {
		[styles.rainbow]: label.toUpperCase() === 'RAINBOW',
		[styles['image']]: FRAME_COLOR_IMAGE_LINKS[label],
	});

	const checkmarkClasses = cn(styles.checkmark, {
		[styles.checkmarkBlack]: label.includes('White') || label.includes('Yellow'),
	});
	const labelWithoutSun = label.replace('Sun - ', '');

	const swatchColor = REFINEMENT_FILTER_COLORS[(labelWithoutSun as string).toUpperCase()];
	if (swatchColor) {
		const dataTags = {
			'data-filter-refinement-option': label,
			[`data-bf-filter-${refinementType}`]: value,
		};

		return (
			<li
				key={`refinement-swatch-${label}`}
				className={cn(styles.container, { [styles['buildflow']]: type === 'buildflow' })}
				onClick={() => {
					refine(value);
				}}
				role='button'
				title={label}
				{...dataTags}
			>
				<div className={styles.wrapper}>
					<button
						className={swatchClasses}
						style={{ backgroundColor: swatchColor }}
						data-color={labelWithoutSun}
						title={label}
						data-image-label={label}
					>
						{isRefined && <Checkmark className={checkmarkClasses} />}
					</button>
				</div>
				<TypographyButton small className={styles['label']}>
					{capitalize(labelWithoutSun, true)}
				</TypographyButton>
			</li>
		);
	}
};

export default RefinementSwatch;

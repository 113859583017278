import { Flex, Icon, Paragraph, ProgressBar } from '@components';
import { getRewardMessage, loyaltyStatusInfo } from '@utils/loyalty';
import { useIsMobile, useTranslation } from '@utils/hooks';
import styles from './RewardsDetail.module.scss';

const getTranslatedTexts = translator => {
	return {
		pairPointsReward: (pointsBalance: string) => {
			return translator('pair-points-reward', { pointsBalance });
		},
		pairPointsUntilReward: (pointsBalance: number, nextRewardPoints: number) => {
			return translator('pair-points-until-reward', { pointsBalance, nextRewardPoints });
		},
		status: translator('status'),
	};
};

export const RewardsDetail = ({ rewardDetail }) => {
	const isMobile = useIsMobile();

	const tierName = rewardDetail?.vip_tier_name || 'Spectator';
	const pointsBalance = rewardDetail?.points_balance || 0;
	const reward = getRewardMessage(pointsBalance);
	const pointsToNextTier = pointsBalance && reward.next ? (pointsBalance / reward.next) * 100 : 100;
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);

	const emphasizeWord = (text: string, emphasizedWord: string, styledText: React.JSX.Element) => {
		const escapedDelimiter = emphasizedWord.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
		const splitedText = text.split(new RegExp(`(${escapedDelimiter})`)).filter(Boolean);

		return (
			<>
				{splitedText.map((text, index) =>
					text === emphasizedWord ? <span key={index}>{styledText}</span> : <span key={index}>{text}</span>
				)}
			</>
		);
	};

	return (
		<div className={styles.container}>
			<header>
				<Flex gap={isMobile ? 4 : 5} align='center'>
					<Flex justify='center' align='center' gap={4}>
						<Icon shape='circle' label='loyalty-status-badge'>
							<img src={`/loyalty-${tierName.toLowerCase()}.svg`} alt='status' />
						</Icon>
						<Flex column className={styles.coloredText} style={{ color: loyaltyStatusInfo[tierName] }}>
							<Paragraph>{tierName.toUpperCase()}</Paragraph>
							<Paragraph>{translations.status.toUpperCase()}</Paragraph>
						</Flex>
					</Flex>
					<div className={styles.verticalSeparator} />
					<Paragraph className={styles.title}>
						{emphasizeWord(
							translations.pairPointsReward(pointsBalance),
							String(pointsBalance),
							<span style={{ color: loyaltyStatusInfo[tierName] }}>{pointsBalance}</span>
						)}
					</Paragraph>
				</Flex>
			</header>
			<Flex column className={styles.content}>
				<Paragraph className={styles.subtitle}>
					{emphasizeWord(
						reward.message,
						String(reward.value),
						<span style={{ color: loyaltyStatusInfo[tierName] }}>{reward.value}</span>
					)}
				</Paragraph>
				<Flex align='center'>
					<div style={{ width: '100%' }}>
						<ProgressBar width={`${pointsToNextTier}%`} height='16px' background={loyaltyStatusInfo[tierName]} />
					</div>
				</Flex>
				{reward.next && (
					<Paragraph className={styles.label}>
						{translations.pairPointsUntilReward(pointsBalance, reward.next)}
					</Paragraph>
				)}
			</Flex>
		</div>
	);
};

import { OrderDetailPOMS } from '@ts/poms/orders';
import { Caption, Flex, Heading, Paragraph } from '@components';
import variables from '@styles/export.module.scss';
import { useTranslation } from '@utils/index';
import { ProductList } from './ProductList';

type OrderDetailProps = {
	order: OrderDetailPOMS;
};

const getTranslatedTexts = translator => {
	return {
		taxIncluded: translator('tax-included'),
		total: translator('total'),
		orderLineItems: (orderItems: number) => {
			return translator('order-line-items', { orderItems });
		},
	};
};

export const OrderDetail = ({ order }: OrderDetailProps) => {
	const { line_items, total_price } = order;
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);

	return (
		<div>
			<ProductList items={line_items} />
			<Flex justify='between' align='baseline'>
				<Flex gap={2} align='baseline'>
					<Heading style={{ fontSize: '1.6rem' }}>{translations.total}</Heading>
					<Caption small color={variables.gray4}>
						{translations.orderLineItems(line_items.length)}
					</Caption>
				</Flex>
				<Flex gap={2} align='baseline'>
					<Caption small color={variables.gray4}>
						({translations.taxIncluded})
					</Caption>
					<Paragraph style={{ fontWeight: '500' }}>{total_price}</Paragraph>
				</Flex>
			</Flex>
		</div>
	);
};

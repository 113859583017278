import { ACCOUNT_TABS, YOTPO } from '@constants';
import { Heading, Paragraph, YotpoActivityWidgetBanner, YotpoWidget } from '@components';
import { useLoadYotpo } from '@services/yotpo';
import { Customer } from '@ts/shopify-storefront-api';
import { useTranslation } from '@utils/index';
import styles from './Rewards.module.scss';

const getTranslatedTexts = translator => {
	return {
		welcomeBackText: (customerName: string) => translator('welcome-back-text', { customerName }),
		earnMorePoints: translator('earn-more-points'),
		tapRewardToLearn: translator('tap-reward-to-learn'),
		yourRewardLevel: translator('your-reward-level'),
		yourFriendsMail: translator('your-friends-mail'),
	};
};

const Rewards = ({ customer }: { customer: Customer }) => {
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);

	useLoadYotpo();
	if (!customer) return null;
	return (
		<div className={styles.myRewards}>
			<div className={styles.responsiveContentContainer}>
				<section>
					<Heading tag='h5' style={{ margin: '3.2rem 0', fontSize: '2.4rem' }}>
						{customer ? translations.welcomeBackText(customer.firstName) : ''}
					</Heading>
					<YotpoActivityWidgetBanner widgetId={YOTPO.ACTIVITY_WIDGET_STYLE2_ID} tab={ACCOUNT_TABS.MY_REWARDS} />
				</section>
				<section className={styles.campaignsSection}>
					<Heading style={{ lineHeight: '4rem', fontSize: '2.6rem' }} tag='h5'>
						{translations.earnMorePoints}
					</Heading>
					<Paragraph>{translations.tapRewardToLearn}</Paragraph>
					<YotpoWidget widgetId={YOTPO.CAMPAIGNS_WIDGET_ID} />
				</section>
				<section>
					<Heading tag='h5'>{translations.yourRewardLevel}</Heading>
					<YotpoWidget widgetId={YOTPO.VIP_TIERS_WIDGET_ID} />
				</section>
			</div>
			<section>
				<YotpoWidget
					widgetId={YOTPO.REFER_A_FRIEND_WIDGET_ID}
					referral-views-friends-input-placeholder={translations.yourFriendsMail}
				/>
			</section>
		</div>
	);
};

export default Rewards;

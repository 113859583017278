import { useTranslation } from '@utils/index';
import AccountCard, { AccountCardProps } from '../AccountCard/AccountCard';

const getTranslatedTexts = translator => {
	return {
		orderedDate: (orderedDate: string) => {
			return translator('ordered-date', { orderedDate });
		},
		orderNumberMessage: (orderNumber: string) => {
			return translator('order-number-message', { orderNumber });
		},
		recentOrder: translator('order-tracker'),
	};
};

const OrderDetail = ({ order, ...rest }: Omit<AccountCardProps, 'children'>) => {
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);
	if (!order) return null;

	return (
		<AccountCard
			order={order}
			title={translations.recentOrder.toUpperCase()}
			label={translations.orderedDate(order?.date)}
			subtitle={translations.orderNumberMessage(order?.order_number).toUpperCase()}
			{...rest}
		/>
	);
};

export default OrderDetail;

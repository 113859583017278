import { memo } from 'react';
import { Button, CollectionTile, Flex, Grid, Heading, Modal, RectangleMatrix } from '@components';
import { NormalizedBuildFlowCollection } from '@ts/product';
import styles from './CollectionDropdown.module.scss';
import { transformActiveCollections, useTranslation } from '@utils/index';

type CollectionDropdownProps = {
	collections: NormalizedBuildFlowCollection[];
	handleSelectCollection: (args: number) => void;
};

const getTranslatedTexts = translator => {
	return {
		selectCollection: translator('select-collection'),
		closeUppercase: translator('close-uppercase'),
	};
};

const CollectionDropdown = ({ collections, handleSelectCollection }: CollectionDropdownProps) => {
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);

	return (
		<Modal>
			<Modal.Trigger asChild>
				<RectangleMatrix xlength={3} ylength={3} />
			</Modal.Trigger>
			<Modal.Content customClose className={styles.modal}>
				<>
					<Flex align='center' justify='between' className={styles.header}>
						<Heading tag='h5'>{translations.selectCollection}</Heading>
						<Modal.Close asChild>
							<Button removeEffects border='none' padding='0' color='white'>
								{translations.closeUppercase}
							</Button>
						</Modal.Close>
					</Flex>
					<Grid columns={3} gap={4} className={styles.collections}>
						{collections.map((collection, index) => {
							return (
								<Modal.Trigger asChild key={`${collection.handle}-tile`}>
									<CollectionTile
										collection={collection}
										onClick={() => handleSelectCollection(index)}
										hasLink={false}
									/>
								</Modal.Trigger>
							);
						})}
					</Grid>
				</>
			</Modal.Content>
		</Modal>
	);
};

export default memo(CollectionDropdown);

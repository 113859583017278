import cn from 'classnames';
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';
import { Flex, Lozenge } from '@components/common';
import { Paragraph, Title } from '@components/typography';
import radioStyles from '@components/common/RadioGroup/RadioGroup.module.scss';
import variables from '@styles/export.module.scss';
import { RichMedia } from '@components/card';
import styles from './OptionCard.module.scss';

type OptionCardProps = {
	isSelected: boolean;
	id: string;
	value: string;
	title: string;
	description?: string;
	price?: string;
	tag?: string;
	onClick: () => void;
	image?: string;
	priceDescription?: string;
	compare_at_price?: string;
	isBuildFlow?: boolean;
};

const OptionCard = ({
	isSelected,
	id,
	value,
	title,
	description,
	price,
	onClick,
	tag,
	image,
	priceDescription,
	compare_at_price,
	isBuildFlow,
	...props
}: OptionCardProps) => {
	const classes = cn(styles['list-item'], {
		[styles['list-item--selected']]: !!image && isSelected,
		[styles['list-item--background']]: !!image,
	});

	const maxWidth = price ? 'calc(100% - 4.8rem)' : 'calc(100% - 1.2rem)';

	return (
		<div key={value} id={value} className={classes} onClick={() => onClick()} {...props}>
			<div className={cn({ [styles['img-container']]: !!image })}>
				{!image && (
					<RadioGroupPrimitive.Item
						aria-checked={isSelected}
						checked={isSelected}
						id={id}
						className={radioStyles.radioOuter}
						value={value}
					>
						<RadioGroupPrimitive.RadioGroupIndicator forceMount className={radioStyles.radioInner}>
							<div className={radioStyles.radioDot} />
						</RadioGroupPrimitive.RadioGroupIndicator>
					</RadioGroupPrimitive.Item>
				)}
				{image && (
					<RichMedia
						containerType={'line-item'}
						src={image}
						title={title}
						aspectRatio={'4/3'}
						className={styles['rich-media']}
						forceDefault={isBuildFlow}
					/>
				)}
			</div>
			<Flex
				fullHeight
				fullWidth
				className={cn({ [styles['copy-container']]: !!image, [styles['copy-container-radio']]: !image })}
			>
				<Flex
					column
					align='center'
					justify={'center'}
					fullHeight
					style={{ marginLeft: '1.6rem', marginTop: '0.4rem', backgroundColor: 'transparent', maxWidth: maxWidth }}
				>
					<Title style={{ marginBottom: '0.4rem' }}>{title}</Title>
					{tag && (
						<Lozenge
							extraClasses={styles.tag}
							shape='square'
							backgroundColor={variables.gray2}
							color={variables.gray4}
						>
							{tag}
						</Lozenge>
					)}
					<Paragraph>{description}</Paragraph>
				</Flex>
				<Flex gap={2}>
					{price && (
						<Paragraph className={cn(styles.price, { [styles['price-red']]: !!compare_at_price })}>{price}</Paragraph>
					)}
					{compare_at_price && <span className={styles.compareAtPrice}>{compare_at_price}</span>}
				</Flex>
				{priceDescription && (
					<Paragraph className={cn(styles.priceDescription, { [styles['priceDescription--withImage']]: !!image })}>
						{priceDescription}
					</Paragraph>
				)}
			</Flex>
		</div>
	);
};

export default OptionCard;

import { Caption, Flex, Spacer, StatusGauge } from '@components';
import variables from '@styles/export.module.scss';
import { OrderDetailPOMS } from '@ts/poms/orders';
import { useTranslation } from '@utils/index';
import { ProductList } from './ProductList';

type OrderDetailProps = {
	order: OrderDetailPOMS;
};

const getTranslatedTexts = translator => {
	return {
		orderedDate: (orderedDate: string) => {
			return translator('ordered-date', { orderedDate });
		},
	};
};

export const OrderTracking = ({ order }: OrderDetailProps) => {
	const { statuses, line_items } = order;
	const isCancelled = statuses.length === 2;
	const statusNumber = statuses.findLastIndex(status => status.completed) + 1;
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);

	return (
		<>
			<StatusGauge
				status={statusNumber}
				nodes={statuses.length}
				fillColor={isCancelled ? variables.orange3 : undefined}
				horizontal
			/>
			<Spacer size={'0.8rem'} />
			<Caption small color={variables.gray4}>
				{/* Ordered on {order?.date} */}
				{translations.orderedDate(order?.date || '')}
			</Caption>
			<Flex fullWidth column style={{ paddingTop: '1.6rem' }}>
				<ProductList items={line_items} />
			</Flex>
		</>
	);
};
